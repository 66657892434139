<template>
  <div class="container width100 box-bb flex">
    <div class="container_tab width100 flex-jc-sb flex box-bb">
      <div class="flex tab box-bb position-re" v-for="(o, i) in tabMsg" :key="i" @click="toPath(o)">
        <div class="tab_info all100 box-bb flex flex-jc-sb flex-ai-fs">
          <span class="text">{{ o.text }}</span>
          <span class="num">{{ o.num }}</span>
        </div>
        <div class="tab_img all100 position-ab">
          <img :src="o.img" alt="" class="all100" />
        </div>
      </div>
    </div>
    <!-- <div class="container_line width100 box-bb"></div> -->
  </div>
</template>
<script>
import { statisticsNumber } from '@/api/modular/fileStream/documentManage'
export default {
  data() {
    return {
      tabMsg: {
        receivedNum: {
          route: {
            path: '/mobileDocumentApproval',
            query: {
              index: 2,
            },
          },
          text: '已收公文',
          img: require('@/assets/img/mobile/ysgw.png'),
          num: 0,
          color: 'rgba(255, 138, 33, 0.25)',
          bg_color: 'rgba(255, 138, 33, 0.25)',
        },
        auditedNum: {
          route: {
            path: '/mobileDocumentApproval',
            query: {
              index: 1,
            },
          },
          text: '已审公文',
          img: require('@/assets/img/mobile/ysh.png'),
          num: 0,
          color: 'rgba(241, 8, 8,0.25)',
        },
        acceptSendNum: {
          route: {
            path: '/mobileDocumentApproval',
            query: {
              index: 3,
            },
          },
          text: '文书归档',
          img: require('@/assets/img/mobile/wsgd.png'),
          num: 0,
          color: 'rgba(110, 84, 246,0.25)',
          bg_color: 'rgba(255, 175, 54, 0.15)',
        },
      },
    }
  },
  mounted() {
    this.getStatisticsNumber()
  },
  methods: {
    getStatisticsNumber() {
      statisticsNumber()
        .then((res) => {
          if (res.code === 200) {
            const keys = Object.keys(res.data)
            keys.forEach((element) => {
              if (this.tabMsg[element]) {
                this.tabMsg[element].num = res.data[element]
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toPath({ route }) {
      route && this.$router.push(route)
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 60px 0 0;
  background: url('../../images/home_bg.png') no-repeat;
  background-position: center;
  background-size: 100%;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 20px;
  .container_tab {
    padding: 0 12px;
    top: 45px;
  }
  .tab {
    flex: 1;
    border-radius: 5px;
    // background-color: red;
    // padding: 40px;
    height: 85px;
    width: 110px;
    flex: 1;
    z-index: 2;
    .tab_info {
      padding: 10px;
      flex-direction: column;
      z-index: 2;
      color: #fff;
      font-size: 13px;
      .num {
        font-size: 23px;
      }
    }
  }
  .container_line {
    height: 60px;
    background-color: #fff;
    border-radius: 50% 50% 0% 0%;
    width: 105%;
  }
  .tab:nth-of-type(2) {
    margin: 0 10px;
  }
}
</style>